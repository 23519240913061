import React from 'react';
import { forwardRef } from 'react';
import VuiBoxRoot from 'components/atoms/VuiBox/VuiBoxRoot';
import PropTypes from 'prop-types';
const VuiBox = forwardRef(({ variant, background, color, opacity, borderRadius, shadow, ...rest }, ref) => (
  <VuiBoxRoot {...rest} ownerState={{ variant, background, color, opacity, borderRadius, shadow }} ref={ref} />
));

VuiBox.defaultProps = {
  variant: 'contained',
  background: 'transparent',
  color: 'dark',
  opacity: 1,
  borderRadius: 'none',
  shadow: 'none'
};

VuiBox.propTypes = {
  variant: PropTypes.oneOf(['contained', 'gradient']),
  background: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.number,
  borderRadius: PropTypes.string,
  shadow: PropTypes.string
};
export default VuiBox;
