import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import VuiBox from 'components/atoms/VuiBox';
import DialogActions from '@mui/material/DialogActions';
import { Button, Box, Stack } from '@mui/material/';
import { CircularProgress } from '../../node_modules/@mui/material/index';
import InfoIcon from '@mui/icons-material/Info';
function NoticeAlertDialog({ open, onClose, title, content, onConfirm }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogActions>
        <Stack>
          <VuiBox display="flex" justifyContent="center">
            <InfoIcon />
          </VuiBox>
          <DialogContent>{content}</DialogContent>

          <Button onClick={onConfirm} color="primary" autoFocus>
            Okay
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default NoticeAlertDialog;
