import React from 'react';
import { Box, Card, CardContent, Grid, InputBase, Paper, Modal, OutlinedInput, Stack, styled } from '@mui/material';
import { useState, useEffect, useRef, useContext } from 'react';
import { AccountContext } from 'context/AccountProvider';
import SendIcon from '@mui/icons-material/Send';
import '../../typing.css';

import ResponseComponent from 'components/chatsAsIsDisplay';
import { UsersContext } from 'store/api/usersApi';
import VuiBox from 'components/atoms/VuiBox';
import VuiButton from 'components/atoms/VuiButton';
import VuiTypography from 'components/atoms/VuiTypography';
import { useTheme } from '@emotion/react';
import VuiModal from 'components/atoms/VuiModal';

const TestMessages = () => {
  const [open, setOpen] = useState(true);
  const { demoAgent } = useContext(UsersContext);
  const { selectedAgents } = useContext(AccountContext);
  const [messages, setMessages] = useState([]);
  const [typing, setTyping] = useState(true);
  const [name, setName] = useState('');
  const [otherText, setOtherText] = useState('');
  const scrollRef = useRef();
  const [value, setValue] = useState('');
  const theme = useTheme();
  const handleInitChat = () => {
    // const data = {
    //   contact_info: { text: otherText, name: name },
    //   messages: []
    // };
    setTyping(true);
    const data = {
      id: selectedAgents.id,
      payload: {
        contact_info: { text: otherText, name: name },
        messages: []
      }
    };
    handleClose();
    demoAgent(data).then((res) => {
      console.log(res);
      setTyping(false);
      let tmp = [
        {
          role: res?.data?.role,
          content: res.data.content
        }
      ];
      setMessages((prevData) => [...tmp, ...prevData]);
      setTyping(false);
    });
  };
  const chatsBodyRef = useRef(null);
  const handlSendChat = () => {
    const newMessage = [
      {
        role: 'user',
        content: value
      }
    ];
    console.log(newMessage);
    console.log(messages);

    setMessages((prevData) => [...newMessage, ...prevData]);
    setValue('');
    setTyping(true);

    const data = {
      id: selectedAgents.id,
      payload: {
        messages: [...messages, ...newMessage]
      }
    };

    demoAgent(data).then((res) => {
      console.log(res);
      let tmp = [
        {
          role: res.data.role,
          content: res.data.content
        }
      ];
      setMessages((prevData) => [...tmp, ...prevData]);
      setTyping(false);
    });
  };
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ transition: 'smooth' });
  }, [messages]);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <VuiBox sx={{ height: '85vh', display: 'flex', width: '100%', flexDirection: 'column' }}>
      <Paper
        ref={scrollRef}
        sx={{
          flexGrow: 1,
          width: '90%',
          overflowY: 'auto',
          marginTop: '4px',
          marginBottom: '4px',
          margin: 'auto',
          borderRadius: '20px',
          padding: theme.spacing(2),
          background: theme.palette.mode === 'dark' ? 'gray' : '#f7f7f7w',
          display: 'flex',
          flexDirection: 'column-reverse'
        }}
      >
        <Container>
          {typing == true ? (
            <Incoming>
              <div className="chat-container">
                <span className="typing-animation">typing ...</span>
              </div>
            </Incoming>
          ) : (
            <></>
          )}
        </Container>
        {messages?.map((message, index) => (
          <Container key={index}>
            {message?.role === 'user' ? (
              <Own>
                <TextMessage message={message} />
              </Own>
            ) : (
              <Incoming>
                <TextMessage message={message} />
              </Incoming>
            )}
          </Container>
        ))}
      </Paper>
      <VuiBox
        elevation={2}
        sx={{
          borderRadius: '20px',
          margin: 'auto',
          width: '90%',
          boxShadow: 24,
          backgroundColor: theme.palette.mode === 'dark' ? '#383535' : '#ededed',
          padding: '0px'
        }}
      >
        <FooterContainer>
          <SearchText>
            <InputField
              disabled={typing}
              onChange={(e) => setValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handlSendChat();
                }
              }}
              placeholder="Type a message"
              value={value}
            />
          </SearchText>
          <SendIcon onClick={() => handlSendChat()} />
        </FooterContainer>
      </VuiBox>
      <VuiModal
        aria-describedby="modal-modal-description"
        aria-labelledby="modal-modal-title"
        onClose={handleClose}
        open={open}
        sx={{ width: '100%', margin: 'auto', top: '10%' }}
      >
        <VuiBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ maxWidth: 600, background: 'white' }} variant="outlined">
                <CardContent>
                  <VuiTypography gutterBottom sx={{ textAlign: 'center' }} variant="h3">
                    Demo Contact Information
                  </VuiTypography>
                  <Stack spacing={1}>
                    <VuiTypography component="p" mt="14px" sx={{ textAlign: 'left' }} variant="p">
                      Name
                    </VuiTypography>

                    <OutlinedInput
                      fullWidth
                      id="name"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="john doe"
                      type="text"
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <VuiTypography component="p" mt="14px" sx={{ textAlign: 'left' }} variant="p">
                      Other info
                    </VuiTypography>

                    <OutlinedInput
                      fullWidth
                      id="text"
                      name="text"
                      value={otherText}
                      onChange={(e) => setOtherText(e.target.value)}
                      placeholder="
                        The Lead is interested in XY program
                        The Lead filled out a question in the form: 'What is the price?'
                        "
                      type="text"
                    />
                  </Stack>

                  <VuiBox alignItems="right" display="flex" fullWidth gap={3} sx={{ mt: 3, justifyContent: 'right' }}>
                    <VuiButton
                      color="secondary"
                      onClick={handleClose}
                      style={{ color: 'black' }}
                      sx={{ borderRadius: '20px' }}
                      variant="contained"
                    >
                      <VuiTypography sx={{ color: 'black' }}>Cancel</VuiTypography>
                    </VuiButton>
                    <VuiButton onClick={() => handleInitChat()} color="info" size="large" sx={{ borderRadius: '20px' }} variant="gradient">
                      Proceed
                    </VuiButton>
                  </VuiBox>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </VuiBox>
      </VuiModal>
    </VuiBox>
  );
};

const TextMessage = ({ message }) => {
  return <ResponseComponent message={message.content} />;
};

const Own = styled(Box)`
  background: #dcf8c6;
  max-width: 60%;
  margin-left: auto;
  padding: 0px;
  width: fit-content;
  display: flex;
  color: black;
  border-radius: 10px;
  word-break: break-word;
`;
const Incoming = styled(Box)`
  background: #ddddff;
  margin-right: auto;
  max-width: 60%;
  color: black;
  padding: 0px;
  width: fit-content;
  display: flex;
  border-radius: 10px;
  word-break: break-word;
`;

const Wrapper = styled(Box)`
  background-size: 50%;
`;
const Container = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 15px;
  padding-bottom: 9px;
  & > * {
    margin: 5px;
    color: #919191;
  }
`;
const FooterContainer = styled(Card)`
  background: #ededed;
  padding: 0px;
  display: flex;
  width: 80%;
  flex-direction: row;
  margin: auto;
  align-items: center;
`;

const SearchText = styled(Box)`
  width: 100%;
`;

const InputField = styled(InputBase)`
  padding: 20px;
  width: 100%;
  background-color: #ffffff;
  marin: auto;
  border-radius: 10px;
  font-size: 15px;
`;
export default TestMessages;
