// ReusableModal.js
import React from 'react';
import PropTypes from 'prop-types';
import VuiBox from 'components/atoms/VuiBox';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme, useMediaQuery } from '@mui/material';

const VuiModal = ({ open, onClose, title, children, ...rest }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const getModalStyle = () => {
    if (isSmallScreen) {
      return {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%'
      };
    } else if (isMediumScreen) {
      return {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%'
      };
    } else {
      return {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%'
      };
    }
  };

  return (
    <Modal {...rest} open={open} onClose={onClose}>
      <VuiBox variant="gradient" sx={{ ...getModalStyle(), position: 'absolute', boxShadow: 24, borderRadius: 2, padding: '20px' }}>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          {title}
        </Typography>
        <VuiBox>{children}</VuiBox>
      </VuiBox>
    </Modal>
  );
};

export default VuiModal;
