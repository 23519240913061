import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import VuiBox from 'components/atoms/VuiBox/index';

import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import ModalExample from 'components/ZoomFitPlugin/ZoomToFitPlugin';
import pdfUrl from 'assets/ivydoc.pdf';
import CustomBottomNavigation from '../menu/BottomNav';
const Docs = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const googleDocsUrl =
    'https://docs.google.com/document/d/e/2PACX-1vRiIDRiqHuTxR0ypYC2qGB4I0tRGkuohtfNcOavJN5qpvrhYRW60IKrC0kH1cNuySYUK7S5kllkJQb7/pub?embedded=true&zoom=10';
  const videoEmbeddUrl = 'https://www.loom.com/embed/2d8dfe98f0914cf2a0d55a92fa299e81?sid=e57ef03d-bd64-449a-9131-df81709f6b48';
  return (
    <VuiBox sx={{ p: 0, background: 'white', height: '95vh', width: '100%' }}>
      <VuiBox
        component="a"
        href="
https://docs.google.com/document/d/1VWmoq52jKWJ7uZf8Il81WEv8kqXxxWkj4y16s8tntqY/edit?usp=sharing
"
        lineHeight={1}
        py={0.75}
        target="_blank"
      >
        Open In google docs
      </VuiBox>
      <VuiBox sx={{ height: 'calc(100vh - 128px)', overflow: 'auto' }}>
        <PanelGroup sx={{ height: '50vh' }} direction={`${isMobile ? 'vertical' : 'horizontal'}`}>
          <Panel defaultSize={50} minSize={20}>
            <div style={{ width: '100%', height: '80vh' }}>
              <iframe
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                allowFullScreen
                src={videoEmbeddUrl}
                style={{ aspectRatio: '16 / 9', width: '100%' }}
                title="Google Docs Viewer"
              />
            </div>
          </Panel>

          <PanelResizeHandle style={{ width: '2px', background: 'gray' }} />
          <Panel defaultSize={50} minSize={20}>
            <ModalExample />
          </Panel>
        </PanelGroup>
      </VuiBox>
    </VuiBox>
  );
};
export default Docs;
