import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import axiosRequest from 'utils/axiosRequest';

const GoogleCalendarCallback = () => {
  const navigate = useNavigate();
  const handleCallbacks = async () => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const redirect_url = 'https://app.steamlined.solutions/GoogleCalendar/callback';
    console.log('calendar');
    console.log(params);
    if (code) {
      try {
        const response = await axiosRequest(
          'POST',
          `https://api.steamlined.solutions/api/v1/callback/google_calendar?code=${code}&redirect_url=${redirect_url}`,
          null,
          null,
          navigate
        );
        if (response.success == true) navigate('/settings/accounts');
      } catch (error) {
        console.error('Error creating user:', error);
      }
    } else {
      // If no code provided in query parameters
    }
  };
  useEffect(() => {
    handleCallbacks();
  }, []);

  return <div>Processing ...</div>;
};

export default GoogleCalendarCallback;
