import React, { useContext } from 'react';
import CodeSnippet from './CodeSnippets';
import VuiBox from 'components/atoms/VuiBox';
import { Modal, Box } from '@mui/material';

const ErrorModal = ({ errorObject, errorModalOpen, onClose }) => {
  const styleinner = {
    width: '100%',
    background: 'white',
    borderRadius: '10px',
    boxShadow: 24,
    maxHeight: '80vh',
    overflow: 'auto',
    p: 2
  };
  console.log(errorObject);
  return (
    <Modal onClose={onClose} open={errorModalOpen} sx={{ width: '70%', margin: 'auto', top: '10' }}>
      <VuiBox style={modalContentStyle} sx={styleinner}>
        <CodeSnippet jsonObject={errorObject} />
      </VuiBox>
    </Modal>
  );
};

const modalContentStyle = {
  backgroundColor: '#fefefe',
  margin: '15% auto',
  padding: '20px',
  border: '1px solid #888',
  width: '80%',
  borderRadius: '10px'
};

const closeButtonStyle = {
  color: '#aaa',
  float: 'right',
  fontSize: '28px',
  fontWeight: 'bold'
};

export default ErrorModal;
