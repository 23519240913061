import React from 'react';

import VuiTypography from 'components/atoms/VuiTypography';
// import { FramerExample } from 'components/molecules/FramerSample/Example';

const SuccessPage = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'white' }}>
      <div
        style={{
          width: 500,
          height: 250,
          borderRadius: '20px',
          backgroundColor: 'black',
          display: 'flex',
          justifyContent: 'center',
          boxShadow: '0 4px 108px rgba(0, 0, 0, 0.5)',
          alignItems: 'center'
        }}
      >
        <VuiTypography color="primary" sx={{ color: 'white', textAlign: 'center' }} variant="h4">
          Ivy will message you soon
        </VuiTypography>
      </div>
    </div>

    // <div className="bodyPanel">
    //   <FramerExample />
    // </div>
  );
};

export default SuccessPage;
