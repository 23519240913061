import React from 'react';
import VuiBox from 'components/atoms/VuiBox';
import { Box, Button, IconButton, Typography, styled } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
const SettingsHeader = ({ text }) => {
  const navigate = useNavigate();

  return (
    <Header>
      <IconButton onClick={() => navigate('/settings')} sx={{ display: { xs: 'block', md: 'none' } }}>
        <ArrowBackIcon />
      </IconButton>
      <SettingsIcon />
      <VuiBox>
        <Name>{text}</Name>
      </VuiBox>

      <RightContainer>
        <Button>{/* <SettingsPowerTwoToneIcon style={{color: person.active == 1 ? '#919191' : 'red'}} size="large" /> */}</Button>
        <MoreVert />
      </RightContainer>
    </Header>
  );
};

const Header = styled(Box)(({ theme }) => ({
  height: '7vh',
  width: '90%',
  borderRadius: '10px',
  margin: 'auto',
  background: theme.palette.mode === 'dark' ? '#383535' : '#ededed',
  padding: '8px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const Name = styled(Typography)`
  margin-left: 12px;
`;

const RightContainer = styled(Box)`
  margin-left: auto;
  & > svg {
    padding: 8px;
    font-size: 24px;
    color: #000;
  }
`;

export default SettingsHeader;
