import React from 'react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import LogRocket from 'logrocket';
import 'assets/css/index.css';
import 'simplebar/src/simplebar.css';
import './assets/third-party/apex-chart.css';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import './index.css';

import * as Sentry from '@sentry/react';

LogRocket.init('fylxtf/ivy');

Sentry.init({
  dsn: 'https://e46b92e7bc0a261d2474f5a49eb9a04c@o4507669709651968.ingest.de.sentry.io/4507669717319760',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <StrictMode>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </StrictMode>
);

reportWebVitals();
