import React from 'react';
import PropTypes from 'prop-types';

import VuiBox from 'components/atoms/VuiBox';
import VuiTypography from 'components/atoms/VuiTypography';
import VuiBadge from 'components/atoms/VuiBadge';

import { useTimeline } from 'examples/Timeline/context';

import { timelineItem } from 'examples/Timeline/TimelineItem/styles';

const TimelineItem = ({ color, icon, title, dateTime, description, badges, lastItem }) => {
  const isDark = useTimeline();

  const renderBadges =
    badges?.length > 0
      ? badges.map((badge, key) => {
          const badgeKey = `badge-${key}`;

          return (
            <VuiBox key={badgeKey} mr={key === badges.length - 1 ? 0 : 0.5}>
              <VuiBadge badgeContent={badge} color={color} container size="xs" />
            </VuiBox>
          );
        })
      : null;

  return (
    <VuiBox mb="24px" position="relative" sx={(theme) => timelineItem(theme, { color })}>
      <VuiBox borderRadius="50%" height="1.625rem" left="-8px" position="absolute" top="3.25%" width="1.625rem" zIndex={2}>
        {icon}
      </VuiBox>
      <VuiBox lineHeight={0} maxWidth="30rem" ml="30px" pt={description ? 0.7 : 0.5}>
        <VuiTypography color="white" fontWeight="medium" variant="button">
          {title}
        </VuiTypography>
        <VuiBox mt={0.5}>
          <VuiTypography color="text" fontWeight="medium" variant="caption">
            {dateTime}
          </VuiTypography>
        </VuiBox>
        <VuiBox mb={1.5} mt={2}>
          {description ? (
            <VuiTypography color="text" fontWeight="regular" variant="button">
              {description}
            </VuiTypography>
          ) : (
            <></>
          )}
        </VuiBox>
        {badges?.length > 0 ? (
          <VuiBox display="flex" pb={lastItem ? 1 : 2}>
            {renderBadges}
          </VuiBox>
        ) : (
          <></>
        )}
      </VuiBox>
    </VuiBox>
  );
};

// Setting default values for the props of TimelineItem
TimelineItem.propTypes = {
  color: 'info',
  badges: [],
  lastItem: false,
  description: ''
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'light']),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  badges: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  lastItem: PropTypes.bool
};

export default TimelineItem;
