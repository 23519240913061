// UserList.js
import React, { useEffect, useState } from 'react';
import deleteIcon from 'assets/images/icons/delete.svg';
import editIcon from 'assets/images/icons/edit.svg';
import { useNavigate } from 'react-router-dom';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Stack, Box, Grid, Paper, Divider, Button } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import avatar4 from 'assets/images/avatar4.png';
import colors from 'assets/theme/base/colors';

import AnchorDemoDrawer from 'pages/landing/TestModal';
import { getSupportTickets } from 'store/api/supportTicket';

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout/index';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar/index';
import VuiBox from 'components/atoms/VuiBox/index';
import { Card, Icon } from '../../../../node_modules/@mui/material/index';
import VuiTypography from 'components/atoms/VuiTypography/index';
import Table from 'examples/Tables/Table/index';
import linearGradient from 'themes/functions/linearGradient';
import VuiButton from 'components/atoms/VuiButton/index';
import VuiBadge from 'components/atoms/VuiBadge/index';
import VuiAvatar from 'components/atoms/VuiAvatar/index';
import CustomLoading from 'components/customLoading/CustomLoading';

function Function({ job, org }) {
  return (
    <VuiBox display="flex" flexDirection="column">
      <VuiTypography variant="caption" fontWeight="medium" color="white">
        {job}
      </VuiTypography>
      <VuiTypography variant="caption" color="text">
        {org}
      </VuiTypography>
    </VuiBox>
  );
}

function Author({ image, name, email }) {
  return (
    <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
      <VuiBox mr={2}>
        <VuiAvatar src={image} alt={name} size="sm" variant="rounded" />
      </VuiBox>
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {name}
        </VuiTypography>
        <VuiTypography variant="caption" color="text">
          {email}
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
}

const TicketList = () => {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedDetail, setSelectedDetail] = useState();
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setModalOpen] = useState(false);
  const handleOpenEdit = () => setModalOpen(true);
  const handleCloseEdit = () => setModalOpen(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const navigate = useNavigate();
  const convertedRows = (tickets) => {
    const convertedRows = tickets.map((item) => ({
      user_name: <Author image={avatar4} name={item.user_name} email={item.user_name} />,
      Category: <Function job={item.Category} org="" />,
      Notes: (
        <VuiBadge
          variant="standard"
          badgeContent={item.Notes}
          color="success"
          size="xs"
          container
          sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
            background: success.main,
            border: `${borderWidth[1]} solid ${success.main}`,
            borderRadius: borderRadius.md,
            color: white.main
          })}
        />
      ),
      action: (
        <>
          <VuiBox mr={1}>
            <VuiButton variant="text" color="text">
              EDIT
            </VuiButton>

            <VuiButton variant="text" color="error">
              DELETE
            </VuiButton>
          </VuiBox>
        </>
      )
    }));

    return convertedRows;
  };
  useEffect(() => {
    setLoading(true);
    getSupportTickets({ navigate }).then((res) => {
      setLoading(false);
      setRows(convertedRows(res.data));
    });
  }, []);

  const [rows, setRows] = useState([]);
  const columns = [
    { label: 'UserName', name: 'user_name', align: 'left' },
    { label: 'Category', name: 'Category', align: 'left' },
    { label: 'Notes', name: 'Notes', align: 'left' },
    { label: 'action', name: 'action', align: 'left' }
  ];
  const [deleteOpen, setDeleteOpen] = useState(false);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <AnchorDemoDrawer open={openModal} setOpen={setModalOpen} handleClose={handleCloseEdit} user={selectedDetail} />
      {loading ? (
        <>
          <Card sx={{ height: '40vh' }}>
            <CustomLoading message="Loading ..." />
          </Card>
        </>
      ) : (
        <VuiBox background="blue" py={3}>
          <VuiBox mb={3}>
            <Card
              sx={{
                borderRadius: '30px',
                p: 5,
                background: linearGradient('gray', gradients.sidenav.state, gradients.sidenav.deg)
              }}
            >
              <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
                <VuiTypography variant="lg" color="white">
                  Support Tickets
                </VuiTypography>
              </VuiBox>
              <VuiBox
                sx={{
                  '& th': {
                    borderBottom: ({ borders: { borderWidth }, palette: { grey } }) => `${borderWidth[1]} solid ${grey[700]}`
                  },
                  '& .MuiTableRow-root:not(:last-child)': {
                    '& td': {
                      borderBottom: ({ borders: { borderWidth }, palette: { grey } }) => `${borderWidth[1]} solid ${grey[700]}`
                    }
                  }
                }}
              >
                <Table columns={columns} rows={rows} />
              </VuiBox>{' '}
            </Card>
          </VuiBox>
        </VuiBox>
      )}
    </DashboardLayout>
  );
};

export default TicketList;
