import * as React from 'react';
import Drawer from '@mui/material/Drawer';

import AgentsInfo from 'pages/adminPanel/users/userDetails';
import SettingsHeader from 'components/templates/chat/SettingsHeader';
import VuiBox from 'components/atoms/VuiBox';
const AnchorDemoDrawer = ({ handleClose, user, open }) => {
  return (
    <div id="mytoggle">
      <Drawer anchor="right" id="newtoggle" onClose={handleClose} open={open}>
        <VuiBox sx={{ width: 500 }}>
          <SettingsHeader text="User Details" />
          <AgentsInfo agents={user} />
        </VuiBox>
      </Drawer>
    </div>
  );
};
export default AnchorDemoDrawer;
