import React from 'react';
import { useMemo } from 'react';

// material-ui
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { amber, blueGrey, purple, grey } from '@mui/material/colors';
import globals from 'assets/theme/base/globals';

import Typography from './typography';
// import CustomShadows from './shadows';
import { useTheme } from '@mui/material/styles';
import boxShadow from './functions/boxShadow';
import { hexToRgb } from '../../node_modules/@mui/system/colorManipulator';
import linearGradient from './functions/linearGradient';
import tripleLinearGradient from './functions/tripleLinearGradient';
import pxToRem from './functions/pxToRem';
import rgba from './functions/rgba';
import colors from './base/colors';
import sidenav from 'assets/theme/components/sidenav';
import list from 'assets/theme/components/list';
import boxShadows from './base/boxShadows';
import borders from './base/borders';
import listItem from 'assets/theme/components/list/listItem';
import listItemText from 'assets/theme/components/list/listItemText';
import card from 'assets/theme/components/card';
import cardMedia from 'assets/theme/components/card/cardMedia';
import cardContent from 'assets/theme/components/card/cardContent';
import link from 'assets/theme/components/link';
import Accordion from './overrides/Accordion';

// const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

const themeTypography =
  Typography(` 'Inter,Helvetica,Apple Color Emoji,Segoe UI Emoji,NotoColorEmoji,Noto Color Emoji,Segoe UI Symbol,Android Emoji,EmojiSymbols,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Noto Sans,sans-serif',
`);
const getDesignTokens = (mode) => ({
  typography: themeTypography,
  // customShadows: CustomShadows(theme),
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    tripleLinearGradient,
    pxToRem,
    rgba
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals
      }
    },

    MuiAccordion: { ...Accordion },
    MuiDrawer: { ...sidenav },
    MuiList: { ...list },
    MuiListItem: { ...listItem },
    MuiListItemText: { ...listItemText },
    MuiCard: { ...card },
    MuiCardMedia: { ...cardMedia },
    MuiCardContent: { ...cardContent },
    MuiLink: { ...link }
  },
  boxShadows: { ...boxShadows },
  borders: { ...borders },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          ...colors,
          primary: {
            main: '#0288d1'
          },
          button: {
            primary: '#3B85F4'
          },
          secondary: {
            main: '#D1DBE4'
          },
          divider: amber[200],
          text: {
            primary: '#000000',

            secondary: grey[500],
            disabled: grey[400]
          },
          chat: {
            text: '#000000',
            paper: '#dcf8c6',
            system: '#d4d4d4'
          },
          background: {
            active: '#d1d1d1',
            generate: '#75B622'
          }
        }
      : {
          ...colors,

          primary: purple,
          button: {
            primary: purple
          },
          divider: blueGrey[700],
          background: {
            default: '#323954',
            paper: grey[900],
            active: grey[900]
          },
          chat: {
            text: '#000000',
            paper: '#dcf8c6',
            system: purple[200]
          },
          text: {
            primary: '#fff',
            secondary: grey[100]
          }
        })
  }
});
export const lightTheme = createTheme(getDesignTokens('light'));

export const darkTheme = createTheme(getDesignTokens('light'));

const ThemeCustomization = ({ children }) => {
  const theme = useTheme();

  // const [mode] = React.useState('light');

  // const themeCustomShadows = useMemo(() => CustomShadows(themes), [theme]);

  // const darkTheme = createTheme(getDesignTokens('dark'));

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <>{children}</>
    </ThemeProvider>
  );
};
export default ThemeCustomization;
