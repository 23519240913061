import React, { createContext, useContext, useState, useCallback } from 'react';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState({ message: '', open: false });

  const showLoading = useCallback((message, severity = 'info') => {
    setLoading({ message, open: true });
  }, []);

  const hideLoading = useCallback((message) => {
    setLoading({ message, open: false });
  }, []);

  const closeLoading = () => setLoading((prev) => ({ ...prev, open: false }));

  return <LoadingContext.Provider value={{ loading, showLoading, closeLoading }}>{children}</LoadingContext.Provider>;
};

export const useLoading = () => {
  return useContext(LoadingContext);
};
