import React from 'react';
import './customLoading.css';
const CustomLoading = ({ message }) => {
  return (
    <div id="loader-wraper">
      <h2 className="sign">
        {message}
        <span className="dot">.</span>
        <span className="dot">.</span>
        <span className="dot">.</span>
      </h2>
      <div id="loader" />
      <div className="loader-section section-left" />
      <div className="loader-section section-right" />
    </div>
  );
};

export default CustomLoading;
