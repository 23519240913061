// src/Chatbot.js
import React, { useEffect, useState } from 'react';
import './x/chat.css';
import './x/style.css';
import './x/typing.css';
import Logo from 'assets/images/logo.png';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Fab, TextField } from '@mui/material';
import Typing from './typingComponent';
import { useContacts } from 'store/api/contactApi';
import ResponseComponent from 'components/chatsAsIsDisplay';

import { ChatWidget } from 'livechats';

const Chatbot = ({ apiUrl }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [typing, setTyping] = useState(false);
  const [convo, setConvo] = useState([]);
  const [userMessage, setUserMessage] = useState('');

  const [enable, setEnable] = useState(false);
  const toggleState = () => {
    setConvo([]);
    handleLiveChat();
    setEnable(!enable);
    showOrHideChatBox();
  };

  const showOrHideChatBox = () => {
    const chatbox = document.getElementById('chatbox');
    const button = document.getElementById('toggleButton');

    if (enable) {
      // chatbox.classList.add('chatbox--active');
      toggleIcon(true, button);
    } else {
      // chatbox.classList.remove('chatbox--active');
      toggleIcon(false, button);
    }
  };

  const toggleIcon = (isActive, button) => {
    // Toggle icon logic (example: change button text or icon)
    if (isActive) {
      // button.innerText = 'Hide Chatbox';
    } else {
      // button.innerText = 'Show Chatbox';
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLiveChat();
    }
  };

  const { liveChat } = useContacts();

  const handleLiveChat = () => {
    const newUserMessage = {
      role: 'user',
      content: userMessage
    };

    setConvo([...convo, newUserMessage]);
    const data = {
      user_name: 'Atomic_enrollment',
      agent_id: '7Yuek8JRBEfepLiAMtLN',
      messages: [...convo, newUserMessage]
    };
    setUserMessage('');

    setTyping(true);
    liveChat(data).then((res) => {
      const newMessage = { role: res.data.role, content: res.data.content };
      console.log([...convo, newUserMessage, ...res.data]);
      setConvo([...convo, newUserMessage]);
      const addResponses = (index) => {
        if (index < res.data.length) {
          setTimeout(() => {
            setConvo((prevConvo) => [...prevConvo, res.data[index]]);
            addResponses(index + 1);
          }, 6000); // 3000 milliseconds = 3 seconds
        }
      };

      addResponses(0);
    });
  };

  return (
    // <ExampleComponent userName="Atomic_enrollment" agentId="7Yuek8JRBEfepLiAMtLN" />
    <ChatWidget userName="Atomic_enrollment" agentId="7Yuek8JRBEfepLiAMtLN" />
    // <div className="chatbox">

    //   <div
    //     style={{ opacity: enable ? '100' : '0', display: enable ? 'flex' : 'none' }}
    //     className={`chatbox__support  ${enable ? 'chatbox--active' : ''}`}
    //   >
    //     <div className="chatbox__header">
    //       <div className="chatbox__content--header">
    //         <h4 className="chatbox__heading--header">Chat support Bot</h4>
    //         <p className="chatbox__description--header">Powered Steamlined Solutions</p>
    //       </div>
    //     </div>
    //     <div className="chatbox__messages">
    //       <div id="chatArea">
    //         {convo?.map((message, index) => {
    //           return (
    //             <div
    //               key={index}
    //               className={`messages__item ${message.role == 'assistant' ? 'messages__item--visitor' : 'messages__item--operator'}`}
    //             >
    //               <ResponseComponent message={message?.content} />
    //             </div>
    //           );
    //         })}
    //         {typing && <Typing />}
    //       </div>
    //     </div>

    //     <div className="chatbox__footer">
    //       <TextField
    //         // multiline
    //         disabled={typing}
    //         style={{ width: '80%', background: 'white' }}
    //         id="userMessage"
    //         value={userMessage}
    //         onKeyPress={handleKeyPress}
    //         onChange={(e) => setUserMessage(e.target.value)}
    //         placeholder="Write a message..."
    //         variant="outlined"
    //         color="secondary"
    //       />
    //       <button className="chatbox__send--footer" onClick={() => handleLiveChat()} id="sendMessage">
    //         Send
    //       </button>
    //     </div>
    //   </div>
    //   <div className="chatbox__button" onClick={() => toggleState()}>
    //     <button style={{ border: 'solid 1px gray' }}>
    //       {enable ? (
    //         <CloseOutlinedIcon style={{ width: '40px', height: '40px' }} />
    //       ) : (
    //         <img src={Logo} style={{ width: '40px', height: '40px' }} />
    //       )}
    //     </button>
    //   </div>
    // </div>
  );
};

export default Chatbot;
